export const preventDuplicateArray = <T extends {id: string}>(array: T[]): T[] => {
  return array.filter(
    (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
  );
}

export const isArrayEmpty = (arr: string[]): boolean => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === '') {
      arr[i] = false as any;
    }
  }
  return arr.every((value) => value !== '');
};

export const isNonEmptyArray = (arr: string[]): boolean => {
  return arr.some((value) => typeof value === 'string' && value !== '');
};

export const removeDuplicate =  (array: any[], propertyName: string): any[] => {
  const uniqueMap = new Map();
  array.forEach((item) => {
    const key = item[propertyName];
    if (!uniqueMap.has(key)) {
      uniqueMap.set(key, item);
    }
  });

  return Array.from(uniqueMap.values());
}

